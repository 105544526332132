import React, { useState } from 'react'
import Footer from '../Footer';

export const Contact = () => {
  const [inpval, setINP] = useState(
    {
      email: "",
      contact: "",
      detail: ""

    }
  )
  const setdata = (e) => {
    console.log(e.target.value);
    const { name, value } = e.target;
    setINP((preval) => {
      return {
        ...preval,
        [name]: value
      }
    })
  }
  //send email


  ///endemail
  const addinpdata = async (e) => {
    e.preventDefault();


    const { email, contact, detail } = inpval

    if (email === "") {
      alert("Hacking is not good thing ")
    }



    if (contact === "") {
      alert("contact is important ")
    }



    if (detail === "") {
      alert("we want details ")
    }


    else {
      const res = await fetch("/register", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          email, contact, detail
        })

      }
      );
      const data = await res.json();
      console.log(data);
      if (!data || res.status === 404) {
        alert("Enter all data");

      }
      else {
        alert("Data Saved");

      }
    }

  }

  return (

    <>
      <div className='container-fluid'>
        <div className='row conten'>
          <div className='col-sm-12'>
            <form method='POST'>
              <div className="form-group">
                <label for="exampleInputEmail1">Enter Your Email Address</label>
                <input type="email" class="form-control" value={inpval.email} onChange={setdata} name="email" aria-describedby="emailHelp" placeholder="Enter email" />
                <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small>
              </div>
              <div className="form-group">
                <label for="exampleInputPassword1">Enter Your Contact Number</label>
                <input type="text" class="form-control" value={inpval.contact} onChange={setdata} name="contact" placeholder="Contact Number" />
              </div>
              <div className="form-group form-check">
                <label for="exampleInputPassword1">Enter Your Details</label>
                <input type="text" class="form-control" value={inpval.detail} onChange={setdata} name="detail" placeholder="Detail" />

              </div>
              <button type="submit" onClick={addinpdata} class="btn btn-primary">Submit</button>
            </form>
          </div>
        </div>
      </div>

      <Footer />
    </>

  )
}
export default Contact
