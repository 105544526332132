import React from 'react'

const Heads = () => {
    return (
        <div className='head'>
            <h1>Every Details Matters</h1>
            <h2>WE BUILD YOUR DREAM AROUND YOU</h2>
            <p>Apart from vegetarian cuisines, the venture bestows typical Asian, Middle East,<br/> Italian French, and Continental Cuisines We always plan and customize the menu with the foremost possible presentation,<br/> food planning for its extensive selection of service.</p>
        </div>
    )
}

export default Heads