import React from 'react'
import Footer from '../Footer';
import Ratelist from '../Ratelist';

export const Packages = () => {
    return (
        <>

          <center><h1>Packages</h1></center>


            <Ratelist />


            <Footer />

        </>
    )
}
