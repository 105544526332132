import { useState } from "react"


import Footer from '../Footer';

const Feedback = () => {
    const [inpval, setINP] = useState({

        name: "",
        email: "",
        Contact: "",
        Designation: "",
    })


    const setdata = (e) => {
        console.log(e.target.value);
        const { name, value } = e.target;
        setINP((preval) => {
            return {

                ...preval,
                [name]: value
            }


        })

    }

    return (
        <>
            <div className="describe">
                <center><h1>Contact</h1></center>
                <form>
                    <lable>Name</lable>
                    <input type="text" value={inpval.name} onChange={setdata} name="name" id="done"></input>


                    <lable>email</lable>
                    <input type="text" value={inpval.email} onChange={setdata} name="email" id="done"></input>

                    <lable>contact</lable>
                    <input type="text" value={inpval.contact} onChange={setdata} name="contact"></input>

                    <lable>Designation</lable>
                    <input type="text" value={inpval.Designation} onChange={setdata} name="Designation"></input>

                    <input type="submit"></input>

                </form>

                < Footer />

            </div>
        </>
    )
}

export default Feedback