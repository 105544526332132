import React from 'react'
import Header from './Header';
import Section from './Section';
import Chart from './Chart';
import JasonDataDisplay from './JsonDataDisplay';
import Footer from './Footer';
import Question from './Question';
import Content from './Content';
import Darivate from './Darivate';
import Heads from './Heads';
import Conts from './Conts';
import Videos from './Videos';
import Gallhome from './Gallhome';


const Layout = () => {
  return (

    <>

      <div className='mains'>


        <Header />
        <Section />
        <br /> <br /> <br />
        <Darivate />
        <jsonDataDisplay />

        <Heads />


        


        <Question />

        {/* <Gallhome/> */}



        <Conts />
        <Videos />
        <Footer />


      </div>

    </>

  )
}

export default Layout