import "./App.css";

import NavBar from "./components/NavBar";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Home } from "./components/Pages/Home";
import { About } from "./components/Pages/About";
import { Blog } from "./components/Pages/Blog";
import { Contact } from "./components/Pages/Contact";
import Layout from "./components/Layout";
import Register from "../src/components/Register";
import Feedback from './components/Pages/Feedback';
import Service from './components/Service';
import Gallery from './components/Pages/Gallery';
import { Packages } from "./components/Pages/Packages";


function App() {
  return (


    <>
    
      <Router>

        <NavBar />


        <Routes>

          <Route path="/" element={<Layout />} />
          <Route path="/about" element={<About />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/Service" element={<Service />} />
          <Route path="/Packages" element={<Packages />} />
          <Route path="/Feedback" element={<Feedback />} />
          <Route path="/Gallery" element={<Gallery />} />

        </Routes>


        {/* <Layout /> */}
      </Router>


    </>

  );
}

export default App;
