import React from 'react'

import About from './Pages/Img/About.jpg';

const Section = () => {
  return (
    <div className='section'>



      <h1> OUR PHILOSOPHY</h1>
      <h2>  THE KING OF QUALITY  CATERERS THE CULINARY EXCELLENCE - SINCE 2005</h2>
      <p>We THE KING OF QUALITY  CATERERS are a full-service catering company specialized only in vegetarian<br /> delicacies, comprising a unique presentation, remarkable taste & innovative event styling.<br /> We keep introducing fresh and new vegetarian menu ideas to accomplish the needs of its diverse <br />range of customers. We stand in a distinguished light for the catering service.

      </p>




      <div class="icon-bar">
        <a href="https://www.facebook.com/profile.php?id=61551593320402&mibextid=ZbWKwL" class="facebook"><i class="fa fa-facebook"></i></a>
   
        <a href="https://www.instagram.com/invites/contact/?i=1ecqbxgiotl7c&utm_content=sshkwuo" class="facebook"><i class="fa fa-instagram"></i></a>
        <a href="https://wa.me/8931061504" class="linkedin" style={{backgroundColor:'#075e54'}}><i class="fa fa-whatsapp"></i></a>
      
      </div>
      
    </div>



  )
}

export default Section