import React from 'react'
import Logo from './Pages/Img/Logonew.jpg'

const Footer = () => {
    return (
        <div className='footer'>
            <div className='foot'>
                <br />
                <img src={Logo} style={{ height: '83px' }} />
                <br />
                <hr />
                <br /><br />
                <p>We The King Of Quality Caterers are a full-service catering company specialized only in vegetarian delicacies,
                    comprising a unique presentation, remarkable taste & innovative event styling.</p>
            </div>

            <div className='foot'>
                <h1>FOLLOW US
                </h1>
                <br />
                <hr />

                <br /><br />
                <a href='https://www.facebook.com/profile.php?id=61551593320402&mibextid=ZbWKwL' ><i class="fa fa-facebook-f"></i> Facebook</a><br />
                
                <a href='https://www.instagram.com/invites/contact/?i=1ecqbxgiotl7c&utm_content=sshkwuo'><i class="fa fa-instagram"></i> Instagram</a><br />
                <a href='Tel:8931061504'><i class="fa fa-phone"></i> 8931061504 <i class="fa fa-whatsapp"></i></a>
            </div>




            <div className='foot'>
                <h1>QUCIK LINKS
                </h1>
                <br />
                <hr />
                <br /><br />
                <p>thekingofquality2021@gmail.com</p>
                <a href='about'>About</a><br />
                <a href='contact'>Contact</a><br />
                <a href='service'>Service</a><br />
                <a href='gallery'>Gallery</a>

            </div>

        </div>
    )
}

export default Footer