import React from 'react';
import Video1 from "../Video/vid1.mp4";
import Video2 from "../Video/vid2.mp4";
import Video3 from "../Video/vid15.mp4";
import Video4 from "../Video/vid4.mp4";
import Video5 from "../Video/vid5.mp4";
import Video6 from "../Video/videoking.mp4";


import Video from "../Video/vid9.mp4";





const Videos = () => {
    return (

        <div>
            <br />   <br />

            <center style={{ color: "gold" }}><h1><b>Our Video Gallery</b></h1></center>

            <br />   <br />

            <div className="video-container" id='vid'>
                <video controls className='vid2'>
                    <source src={Video1} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
                <video controls className='vid2'>
                    <source src={Video2} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
                <video controls className='vid2'>
                    <source src={Video3} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
            </div>

            <div className="video-container">
                <video controls className='vid2'>
                    <source src={Video4} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
                <video controls className='vid2'>
                    <source src={Video5} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
                <video controls className='vid2'>
                    <source src={Video6} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
            </div>



            <div className="video-container">
                
                <video controls className='vid2'>
                    <source src={Video} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
            </div>
           



        </div>

    );
}

export default Videos;
