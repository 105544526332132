import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import './const.css';
import Dice from '../Icons/back2.png';

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    address: '',
    foodPreference: '',
    gatheringNumber: '',
    selectedDate: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Use the emailjs.send method to send an email
    emailjs
      .send(
        'service_ze1ljmk',
        'template_w1w63ls',
        formData,
        'ECEIp0ELYr3NjM7iej_Yq'
      )
      .then(
        (response) => {
          console.log('Email sent successfully:', response);
        },
        (error) => {
          console.error('Email failed to send:', error);
        }
      );
  };

  return (
    <div className="contact-container">
      <div className="contact-form">
        <br /><br />
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="name">Name:</label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email:</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="address">Address:</label>
            <textarea
              id="address"
              name="address"
              value={formData.address}
              onChange={handleChange}
              rows="5"
              required
            ></textarea>
          </div>
          <div className="form-group">
            <label htmlFor="foodPreference">Food Preference:</label>
            <select
              id="foodPreference"
              name="foodPreference"
              value={formData.foodPreference}
              onChange={handleChange}
              required
            >
              <option value="" disabled>Select Food Preference</option>
              <option value="vegetarian">Vegetarian</option>
              <option value="nonVegetarian">Non-Vegetarian</option>
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="gatheringNumber">Gathering Number:</label>
            <input
              type="number"
              id="gatheringNumber"
              name="gatheringNumber"
              value={formData.gatheringNumber}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="selectedDate">Select Date:</label>
            <input
              type="date"
              id="selectedDate"
              name="selectedDate"
              value={formData.selectedDate}
              onChange={handleChange}
              required
            />
          </div>
          <button type="submit">Submit</button>
        </form>
      </div>
      <div className="contact-image">
        <img src={Dice} alt="Dice" />
      </div>
    </div>
  );
};

export default ContactForm;
