import React from 'react'
import Serv1 from './Img/Ser1.jpeg';
import Serv2 from './Img/Ser2.jpeg';
import Serv3 from './Img/Ser3.jpeg';
import Serv4 from './Img/Ser4.jpeg';
import Serv5 from './Img/Ser5.jpeg';
import Serv6 from './Img/Ser6.jpeg';
import Footer from '../Footer';
import Gallhome from '../Gallhome';

const Gallery = () => {
    return (
        <>

            <Gallhome />

            <div className='Service'>

                <div className='Serve'>
                    <img src={Serv1} />
                </div>

                <div className='Serve'>
                    <img src={Serv2} />
                </div>

            </div>



            <div className='Service'>


                <div className='Serve'>
                    <img src={Serv3} />
                </div>


                <div className='Serve'>
                    <img src={Serv4} />
                </div>



            </div>

            <div className='Service'>

                <div className='Serve'>
                    <img src={Serv5} />
                </div>

                <div className='Serve'>
                    <img src={Serv6} />
                </div>

            </div>



            <Footer />
        </>

    )
}

export default Gallery