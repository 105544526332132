import React from 'react'

import Rate1 from '../Icons/k1.png'
import Rate2 from '../Icons/k2.png'
import Rate3 from '../Icons/k3.png'
import Rate4 from '../Icons/k4.png'
import Rate5 from '../Icons/k5.png'
import Rate6 from '../Icons/k6.png'

const Ratelist = () => {

    return (
        <div className='rates'>
            <img src={Rate1} />

            <img src={Rate2} />

            <img src={Rate3} />

            <img src={Rate4} />

            <img src={Rate5} />

            <img src={Rate6} />



        </div>
    )

}

export default Ratelist